<template>
  <div class="">
    <div class="card rounded shadow-sm">
      <div class="card-body">
        <div>
          <span
            >Priority Level:
            <i class="bi bi-flag-fill text-danger mx-1"></i> High
            <i class="bi bi-flag-fill text-warning mx-1"></i> Medium
            <i class="bi bi-flag-fill text-secondary mx-1"></i> Low</span
          >
        </div>

        <div class="table-responsive mt-3">
          <table id="incoming-table" class="table table-sm table-hover">
            <thead>
              <th>
                <input
                  id="select-all-check"
                  type="checkbox"
                  style="width: 15px; height: 15px;"
                  v-model="checked"
                  @change="checked ? selectAll() : unselectAll()"
                  :disabled="!documents.length"
                />
              </th>
              <th width="10%">Tracking No</th>
              <th width="35%">Document Title</th>
              <th width="">Origin</th>
              <th width="">Type</th>
              <th>Forwarded by</th>
              <th>Deadline</th>
              <th>Status</th>
            </thead>
            <tbody>
              <tr class="text-center" v-if="!documents.length && !tableLoader">
                <td colspan="11">
                  Hooray! You have no document(s) to receive.
                </td>
              </tr>
              <tr class="text-center" v-if="tableLoader">
                <td colspan="11">
                  <div class="spinner-border spinner-border-sm mr-1"></div>
                  Loading
                </td>
              </tr>
              <tr
                v-for="d in documents"
                :key="d.id"
                @click="openDocument(d)"
                :class="{
                  'table-primary': selected.some((obj) => obj.id == d.id),
                }"
              >
                <td>
                  <input
                    type="checkbox"
                    :value="d"
                    v-model="selected"
                    @click.stop
                    style="width: 15px; height: 15px;"
                  />
                </td>

                <td>
                  DTS-R13-{{ d.id }}
                  <span v-if="d.is_incoming">
                    <i class="bi bi-arrow-down"></i>
                  </span>
                  <span v-else>
                    <i class="bi bi-arrow-up"></i>
                  </span>
                </td>
                <td style="font-weight: 500;">
                  <i :class="[getFlagColor(d.priority_level)]"></i>{{ d.title }}
                </td>

                <td>{{ d.origin }}</td>
                <td>{{ d.document_type }}</td>

                <td>
                  {{ `${d.first_name} ${d.last_name}` }}
                  <small class="form-text text-muted">{{
                    d.forwarded_at | completeDateTime
                  }}</small>
                </td>
                <td>
                  <div v-if="d.deadline !== null">
                    {{ d.deadline | dateTime }}
                    <small class="form-text text-muted"
                      >{{ d.deadline | dueIn }} ({{
                        d.transaction_type
                      }})</small
                    >
                  </div>
                  <span v-else>N/A</span>
                </td>
                <td>
                  <span class="badge badge-pill badge-danger">{{
                    d.status
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <button
            id="receive-btn"
            class="btn btn-primary mr-2"
            @click="receive"
            :disabled="!selected.length"
          >
            <i class="bi bi-arrow-right mr-1"></i>Receive
          </button>
          <button
            class="btn btn-outline-secondary text-dark"
            @click="getIncoming(false)"
          >
            <i class="bi bi-arrow-repeat mr-1"></i>Refresh
          </button>
        </div>
      </div>
    </div>

    <DocumentDetailsModal
      ref="DocumentDetailsModal"
      :canAddActionTaken="false"
    ></DocumentDetailsModal>
  </div>
</template>

<script>
import DocumentDetailsModal from './DocumentDetailsModal.vue';
import { getFlagColor } from '../../../js/helpers';

export default {
  name: 'Incoming',
  components: {
    DocumentDetailsModal,
  },
  data() {
    return {
      documents: [],
      selected: [],
      checked: false,
      tableLoader: false,
    };
  },
  methods: {
    openDocument(document) {
      this.$refs.DocumentDetailsModal.show(document);
    },
    getIncoming(toggle) {
      this.tableLoader = toggle;

      axios
        .get('api/documents/incoming')
        .then((res) => {
          this.documents = res.data;
          this.tableLoader = false;
          this.$emit('updateBadges');
        })
        .catch((err) => {
          this.tableLoader = false;
        });
    },
    receive() {
      let b = document.getElementById('receive-btn');
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> Receiving...`;
      b.disabled = true;

      axios
        .put('api/documents/receive', this.selected)
        .then((res) => {
          b.innerHTML = 'Receive';
          b.disabled = false;
          toastr.success(res.data.msg, 'Information');
          this.removeItem();
          this.$emit('updateBadge');
          this.checked = false;
        })
        .catch((err) => {
          b.innerHTML = 'Receive';
          b.disabled = false;
          toastr.error(err.response.data.message, 'Error');
        });
    },
    removeItem() {
      this.selected.forEach((selectedItem) => {
        let index = this.documents.findIndex(
          (doc) => doc.id == selectedItem.id
        );
        this.documents.splice(index, 1);
      });

      this.selected = [];
    },

    selectAll() {
      this.documents.forEach((doc) => {
        this.selected.push(doc);
      });
    },

    unselectAll() {
      this.documents.forEach((doc) => {
        this.selected.pop(doc);
      });
    },
    getFlagColor,
  },
  mounted() {
    this.getIncoming(true);
  },
};
</script>

<style scoped>
#incoming-table tr {
  cursor: pointer !important;
}
</style>
