<template>
  <div>
    <div class="modal" id="actions-taken-modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form @submit.prevent="addActionTaken">
            <div class="modal-body">
              <ul class="list-group">
                <li
                  v-for="item in selected"
                  :key="item.id"
                  class="list-group-item"
                >
                  <strong>[DTS-R13-{{ item.id }}]</strong> : {{ item.title }}
                </li>
              </ul>
              <br />
              <div class="form-group">
                <label for="">Action/s Taken</label>
                <textarea
                  class="form-control"
                  v-model="action"
                  rows="5"
                  placeholder="Type in here any action/s taken such as 'Data request is being processed'"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
              <button
                id="add-action-taken-btn"
                type="submit"
                class="btn btn-primary"
              >
                Add
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionsTakenModal',
  props: {
    selected: {
      type: Array,
    },
  },
  data() {
    return {
      action: '',
    };
  },
  methods: {
    show() {
      $('#actions-taken-modal').modal('show');
    },
    addActionTaken() {
      let b = document.getElementById('add-action-taken-btn');
      b.disabled = true;
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> Adding...`;

      let params = {
        selected: this.selected,
        action: this.action,
      };

      axios
        .post('api/documents/action-taken/insert', params)
        .then((res) => {
          toastr.success(res.data.msg, 'Information');
          b.innerHTML = 'Add';
          b.disabled = false;
          $('#actions-taken-modal').modal('hide');
        })
        .catch((err) => {
          toastr.error(err.response.data.message, 'Error');
          b.innerHTML = 'Add';
          b.disabled = false;
        });
    },
  },
};
</script>

<style scoped>
#table-of-my-documents {
  vertical-align: top !important;
}
</style>
